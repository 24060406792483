declare global {
  interface Window {
    CustomEvent: any;
  }
}

if (typeof window.CustomEvent !== "function") {
  /**
   *
   * @param {string} event
   * @param {any} params
   * @returns
   */
  window.CustomEvent = function CustomEvent(event, params) {
    const eventParams = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(event, eventParams.bubbles, eventParams.cancelable, eventParams.detail);
    return evt;
  };

  if (window.Event) {
    //@ts-ignore
    CustomEvent.prototype = window.Event.prototype;
  }
  window.CustomEvent = CustomEvent;
}

export default undefined;
