const currentOrigin = window.location.origin;
const testOrigins = [
  "https://a-tech.mos.ru",
  "https://c-tech.mos.ru",
  "https://t-tech.mos.ru",
  "https://newmos.mos.ru",
  "http://local.a-tech.mos.ru",
  "http://local.c-tech.mos.ru",
  "http://local.t-tech.mos.ru",
  "http://c.local.mos-team.ru",
  "http://a.local.mos-team.ru",
  "http://t.local.mos-team.ru",
  "http://local.mos-team.ru",
];

const domain = testOrigins.includes(currentOrigin) ? currentOrigin : "https://www.mos.ru";

export const OWNER_ID = 255877;
export const ADV_API_URL = `${domain}/shared/schmetterling/v2/schmetterling.js`;
