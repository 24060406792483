/**
 * @type {Object}
 */
export const EVENT_TYPES = {
  bannerLoad: "bannerLoad", // Сразу после загрузки
  bannerClick: "bannerClick", // Сразу после клика
  bannerClose: "bannerClose", // Сразу перед закрытием
};

export const METRIKA_URL = "https://mc.yandex.ru/metrika/watch.js";

export const METRIKA_EVENT = {
  bannerLoad: "Load",
  bannerClick: "Click",
  bannerClose: "Close",
};
