import getEdsGif from "@mos/eds-gif";
import { compose } from "./utils/stats";
import { EVENT_TYPES, METRIKA_EVENT, METRIKA_URL } from "./const/stats";
import { isDev } from "./const/shared";
import { documentOnLoad, getMosId, initScript } from "./utils/shared";
import targetReplacer from "./targetReplacer";

declare global {
  interface Window {
    yaCounter45066689: any;
    Ya: any;
    Schmetterling: any;
    mosTizer: any;
    bannerId: any;
  }
}

interface DefaultData {
  bannerId?: BannerId;
  eventSrc: any;
  mosId: any;
}

const isProd = !isDev;
/**
 * @function setBannerId
 * @param {string|number} nextBannerId
 */

/**
 * @todo упростить
 */

type BannerId = string | number | undefined;

const [setBannerId, getBannerId] = (() => {
  let bannerId: BannerId;
  let isBannerIdSet = false;
  return [
    (nextBannerId?: BannerId) => {
      if (!isBannerIdSet) {
        bannerId = nextBannerId;
        isBannerIdSet = true;
      }
    },
    () => bannerId,
  ];
})();

/**
 * @function
 */
const getDefaultData = (() => {
  const defaultData = {} as DefaultData;
  return () => {
    let href;

    try {
      href = window.top.location.href;
    } catch (e) {
      href = window.location.href;
    }

    if (href === defaultData.eventSrc) {
      return defaultData;
    }

    defaultData.bannerId = getBannerId();
    defaultData.mosId = getMosId();
    defaultData.eventSrc = href;
    return defaultData;
  };
})();

/**
 * @return {object}
 * @param {string} eventType
 */
const collectData = (eventType) => ({ ...getDefaultData(), eventType, eventTime: +new Date() });

let metrikaPromise = null;

const getMetrika = () => {
  if (metrikaPromise) return metrikaPromise;
  metrikaPromise = new Promise((resolve) => {
    try {
      const req = new XMLHttpRequest();
      req.addEventListener("load", function initCouter() {
        initScript(this.responseText);
        window.yaCounter45066689 = new window.Ya.Metrika({
          id: 45066689,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        });
        resolve(window.yaCounter45066689);
      });
      req.open("GET", METRIKA_URL);
      req.send();
    } catch (ignore) {
      resolve(null);
    }
  });
  return metrikaPromise;
};

const sendMetric = (data) => {
  getMetrika().then((counter) => {
    if (!counter) {
      return;
    }
    const { eventType, ...eventData } = data;
    try {
      counter.reachGoal("AdFox", {
        [METRIKA_EVENT[eventType]]: {
          [eventData.bannerId]: {
            [eventData.mosId]: {
              [eventData.eventSrc]: eventData.eventTime,
            },
          },
        },
      });
    } catch (ignore) {
      /* ignore*/
    }
  });
  return data;
};

/**
 * @function
 * @return {void}
 */
const pushEvent = compose(
  isProd ? getEdsGif : console.log, // eslint-disable-line no-console
  sendMetric,
  collectData
);

/**
 * @function
 * @return {void}
 */
const click = () => pushEvent(EVENT_TYPES.bannerClick);

/**
 *
 * @param {HTMLElement} element
 */
const attachClickEvent = (element) => {
  element.addEventListener("click", click);
};

/**
 *
 * type {{ pushEvent, close: ((p1:*)), click: (()), init: (()) }}
 */
const stat = {
  pushEvent,
  click,
  init() {
    window.mosTizer = stat;
    documentOnLoad(() => {
      targetReplacer.init();
      setBannerId(window.bannerId);
      pushEvent(EVENT_TYPES.bannerLoad);
      [].forEach.call(window.document.querySelectorAll("a"), attachClickEvent);
    });
  },
};

export default stat;
