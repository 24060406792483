import { MODES } from "../const/shared";

/**
 * @return {string}
 * @param {Window} win
 */
export const getMode = (win) => (win === win.top ? MODES.api : MODES.stats);

/**
 * Do nothing or simply return provided value
 * @return {*}
 * @param {*} [val]
 */
export const noop = (val) => val;

/**
 * @param {function} handler
 */
export const documentOnLoad = (handler) => {
  if (document.readyState !== "loading") {
    handler();
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      handler();
    });
  }
};

/**
 * @function
 * @returns {string} mos_id cookie or null
 */
export const getMosId = () => document.cookie.replace(/(?:(?:^|.*;\s*)mos_id\s*=\s*([^;]*).*$)|^.*$/, "$1");

/**
 * @param {string} src Валидный скрипт. Почти что же что и eval.
 */
export const initScript = (src) => {
  const script = document.createElement("script");
  script.text = src;
  const head = document.head;
  head.appendChild(script);
  head.removeChild(script);
};
