import "promise-polyfill/src/polyfill";
import { getMode, noop } from "./utils/shared";
import { MODES } from "./const/shared";
import api from "./api";
import stats from "./stats";

const init = () => {
  window.mosTizer = { reloadBanner: noop };
  switch (getMode(window)) {
    case MODES.stats: {
      stats.init();
      break;
    }
    /* eslint-disable no-fallthrough */
    case MODES.api:
    default: {
      /* eslint-enable*/
      api.init();
      break;
    }
  }
};

if (!window.mosTizer) init();
