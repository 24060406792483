import { documentOnLoad } from "./utils/shared";

const targetReplacer = {
  init() {
    if (window.mosTizer) {
      return;
    }

    documentOnLoad(() => {
      const isMosEnvironment = /^(newmos\.||\w-tech\.||www\.)?mos\.ru/gi.test(location.host);

      if (!isMosEnvironment) {
        const bannerLinks = document.querySelectorAll("a");
        for (let i = 0; i < bannerLinks.length; i += 1) {
          bannerLinks[i].setAttribute("target", "_blank");
        }
      }
    });
  },
};

export default targetReplacer;
