import fetch from "unfetch";
import "./polyfill";
import { noop, documentOnLoad, initScript } from "./utils/shared";
import { OWNER_ID, ADV_API_URL } from "./const/api";
import adfoxApiReplacer from "./adfox/adfoxApiReplacer";

let replaceAdvApi = process.env.NO_ADV;

let advApiPromise = null;

/**
 * @function
 * @returns {Promise<Object>}
 */

const getPlaceholders = (selector, parent = window.document) => parent.querySelectorAll(selector);

const getAdvApi = () => {
  if (replaceAdvApi) {
    return adfoxApiReplacer;
  }

  if (advApiPromise) {
    return advApiPromise;
  }

  try {
    advApiPromise = fetch(ADV_API_URL)
      .then((r) => r.text())
      .then(initScript)
      .then(() => window.Schmetterling);
    return advApiPromise;
  } catch (ignore) {
    return Promise.reject();
  }
};

const renderedBanners = new WeakMap();

const bannerApi = {
  init: () => {
    window.mosTizer = bannerApi;
    documentOnLoad(bannerApi.renderAll);
    document.dispatchEvent(new CustomEvent("mosTizer.initialized"));
  },
  renderAll(placeholders = getPlaceholders("[data-mos-teaser]")) {
    if (!placeholders.length) return;
    [].forEach.call(placeholders, (elem) => bannerApi.renderBanner(elem));
  },
  reloadAll() {
    getAdvApi().then((advApi) => {
      advApi.reload();
    });
  },
  getBanner(element) {
    return renderedBanners.get(element);
  },
  /**
   * @function
   * @param {HTMLElement} element where to load banner
   * param {Object<fuction>} [callback]
   */
  renderBanner(element, { onLoad = noop, onError = noop, onStub = noop, onRender = noop } = {}) {
    if (renderedBanners.has(element)) return renderedBanners.get(element);

    renderedBanners.set(element, { reload: noop });
    return getAdvApi()
      .then((advApi) => {
        const { placementParams } = JSON.parse(element.dataset.mosTeaser);
        const bannerSettings = {
          ownerId: OWNER_ID,
          params: {
            platform: placementParams.p1,
          },
          element,
          onError,
          onLoad,
          onStub,
          onRender() {
            element.dispatchEvent(new CustomEvent("bannerRendered"));
            onRender(element);
          },
        };

        advApi.create(bannerSettings);
        const banner = Promise.resolve({
          ...bannerSettings,
          iframe: {},
          windowListeners: {},
          bannerId: `mosTizerBanner_${placementParams.p1}`,
          destroy: () => {
            advApi.destroy(element);
          },
        });
        renderedBanners.set(element, banner);

        return banner;
      })
      .catch(noop);
  },
  /**
   * @deprecated
   * @param {HTMLElement} element
   */
  reloadBanner(element) {
    const banner = bannerApi.getBanner(element);
    if (banner) {
      getAdvApi().then((api) => api.reload(element));
    } else {
      bannerApi.renderBanner(element);
    }
  },
  destroyBanner(element) {
    const banner = bannerApi.getBanner(element);

    if (banner) {
      getAdvApi().then((api) => api.destroy(element));
      renderedBanners.delete(element);
    }
  },
};

export default bannerApi;
